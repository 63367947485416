<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <h3 class="mb-0">
            <strong>List Antrian Poli</strong>
          </h3>
          <b-card
            header-tag="header"
            header-bg-variant="dark"
            style="margin-top: 20px"
          >
            <b-row style="display: flex">
              <b-col cols="12" sm="12" md="12" lg="12">
                <b-row>
                  <b-col cols="9" sm="9" md="9" lg="9">
                    <b-card class="bg-secondary text-light">
                      <b-row>
                        <b-col cols="6" sm="6" md="6" lg="6">
                          <b-form>
                            <b-form-group>
                              <template v-slot:label>
                                <b style="color: black;">Poli</b>
                              </template>
                              <b-form-select
                                v-model="poli_id"
                                :options="poli"
                                :disabled="locked"
                              ></b-form-select>
                            </b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" sm="12" md="12" lg="12">
                          <b-button
                            v-if="locked"
                            style="width: 100%"
                            variant="success"
                            @click="hapusData()"
                          >
                            <CIcon name="cil-lockLocked" /> Simpan
                          </b-button>
                          <b-button
                            v-else
                            style="width: 100%"
                            variant="success"
                            @click="simpanData()"
                          >
                            Simpan
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>

                  <b-col cols="3" sm="3" md="3" lg="3">
                    <b-card
                      style=" 
                        flex-flow: column wrap;
                        height: 500px;
                        position: absolute;
                        width: 92%;
                        margin-right: 50px;
                      "
                      class="bg-secondary text-light"
                    >
                      <b-row>
                        <b-col cols="12" sm="12" md="12" lg="12">
                          <h5 style="color: black"><strong>Proses</strong></h5>
                          <hr />
                          <!-- <div style="display: flex">
                            <div>
                              
                            </div>
                            <div>
                              
                            </div>
                          </div> -->
                        </b-col>
                        <b-col cols="12" sm="12" md="12" lg="12">
                          <b-row>
                            <b-col cols="6" sm="6" md="6" lg="6">
                              <h5 style="color: black">
                                <strong>{{ loketnya }}</strong>
                              </h5>
                            </b-col>
                            <b-col
                              cols="2"
                              md="2"
                              lg="2"
                              sm="2"
                              offset-lg="3"
                              offset-md="3"
                              offset-sm="3"
                            >
                              <b-button
                                :disabled="sedang_manggil"
                                style="justify-content;: flex-end"
                                variant="success"
                                class="mr-1"
                                @click="panggil()"
                                v-c-tooltip.hover.click="'Panggil'"
                                ><CIcon name="cil-bell" />
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12" sm="12" md="12" lg="12">
                          <b-row>
                            <b-col
                              style=" 
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 300px;
                              "
                            >
                              <p style="font-size: 90px;color:black">
                                <strong>{{ no_proses }}</strong>
                              </p>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" sm="12" md="12" lg="12">
                              <b-row>
                                <b-col cols="6" sm="6" md="6" lg="6">
                                  <b-button
                                    style="width: 100%"
                                    variant="danger"
                                    @click="batalProses()"
                                    :disabled="disabledBatal"
                                  >
                                    <CIcon name="cil-check" /> Batal
                                  </b-button>
                                </b-col>
                                <b-col cols="6" sm="6" md="6" lg="6">
                                  <b-button
                                    style="width: 100%"
                                    variant="primary"
                                    :disabled="disabledProses"
                                    @click="mulaiProses()"
                                  >
                                    <CIcon name="cil-check" /> Proses
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>

                  <b-col cols="9" sm="9" md="9" lg="9">
                    <b-card class="bg-secondary text-light">
                      <b-row>
                          <h5 style="color: black;"><strong>Antrian</strong></h5>
                          <hr />
                        <b-col cols="12" sm="12" md="12" lg="12">
                          <b-table
                            :items="items"
                            :fields="fields"
                            :current-page="currentPage"
                            :per-page="perPage"
                            show-empty
                            hover
                            class="bg-light"
                          >
                            <template #cell(actions)="item">
                              <b-button
                                variant="success"
                                class="mr-1"
                                :disabled="sedang_manggil"
                                @click="update(item.item)"
                                ><CIcon name="cil-bell" />
                                {{ item.actions }}</b-button
                              >
                              <!-- <b-button
                                variant="warning"
                                class="mr-1"
                                :disabled="sedang_proses"
                                @click="updateTanpaPanggil(item.item)"
                                ><CIcon name="cil-bell-exclamation" />
                                {{ item.actions }}</b-button
                              > -->
                              <b-button
                                variant="danger"
                                class="mr-1"
                                v-c-tooltip.hover.click="'Hapus Data'"
                                v-b-modal.modal-delete
                                @click="dataHapus = item.item"
                                ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                              >
                            </template>
                          </b-table>
                          <b-row>
                            <b-col md="8" offset-md="4">
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" sm="12" md="12" lg="12">
                          <h5 style="color: black;"><strong>Sedang Proses</strong></h5>
                          <hr />
                          <b-table
                            :items="itemsProses"
                            :fields="fieldsProses"
                            :current-page="currentPageProses"
                            :per-page="perPageProses"
                            responsive
                            show-empty
                            hover
                            class="bg-light"
                          >
                            <template #cell(actions)="item">
                              <b-button
                                variant="success"
                                class="mr-1"
                                v-c-tooltip.hover.click="'Panggil'"
                                @click="data = item.item"
                                ><CIcon name="cil-bell" />
                                {{ item.actions }}</b-button
                              >
                            </template>
                          </b-table>
                          <b-row>
                            <b-col md="8" offset-md="4">
                              <b-pagination
                                v-model="currentPageProses"
                                :total-rows="totalRowsProses"
                                :per-page="perPageProses"
                                align="fill"
                                size="sm"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" sm="12" md="12" lg="12">
                          <h5 style="color: black;"><strong>Selesai</strong></h5>
                          <hr />
                          <b-table
                            :items="itemsSelesai"
                            :fields="fieldsSelesai"
                            :current-page="currentPageSelesai"
                            :per-page="perPageSelesai"
                            responsive
                            show-empty
                            hover
                            class="bg-light"
                          >
                            <template #cell(actions)="item">
                              <b-button
                                variant="success"
                                class="mr-1"
                                v-c-tooltip.hover.click="'Panggil'"
                                @click="data = item.item"
                                ><CIcon name="cil-bell" />
                                {{ item.actions }}</b-button
                              >
                              
                            </template>
                          </b-table>
                          <b-row>
                            <b-col md="4" offset-md="8">
                              <b-pagination
                                v-model="currentPageSelesai"
                                :total-rows="totalRowsSelesai"
                                :per-page="perPageSelesai"
                                align="fill"
                                size="sm"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- MODAL HAPUS -->
    <b-modal
      id="modal-delete"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menghapus data ini ?</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-delete')">
          Tidak
        </b-button>
        <b-button variant="primary" @click="hapusAntrian()">
          Ya
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
moment.locale("ID");
export default {
  name: "jenis_antrian",
  data() {
    return {
      dataHapus: "",
      poli: [{ value: null, text: "Silahkan Pilih" }],
      room_id: "",
      idPolinya: "",
      booking_id: "",
      nama_pasien: "",
      disabledDokter: true,
      disabledJadwal: true,
      disabledBatal: true,
      disabledSelesai: true,
      keDisabled: true,
      locked: false,
      sedang_manggil: false,
      sedang_proses: false,
      on_process: false,
      no_proses: "",
      angka_antrean: "",
      loketnya: "",
      no_panggilan: "",
      kode_panggilan: "",
      jenis_antrian_id: null,
      listPoli: [],
      listDokter: [],
      listJadwal: [],
      listLayanan: [],
      antrian_id: "",
      jenis_antrian: [{ value: null, text: "Silahkan Pilih" }],
      poli_id: null,
      loket: [{ value: null, text: "Silahkan Pilih" }],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "no_antrian",
          label: "No. Antrian",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },

        {
          key: "nama_poli",
          label: "Ke Poli",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-4 text-center",
        },
      ],
      fieldsProses: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "no_antrian",
          label: "No. Antrian",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "nama_poli",
          label: "Ke Poli",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      fieldsSelesai: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "no_antrian",
          label: "No. Antrian",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "nama_poli",
          label: "Ke Poli",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      items: [],
      idp: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      itemsSelesai: [],
      totalRowsSelesai: 1,
      currentPageSelesai: 1,
      tampilui: false,
      perPageSelesai: 5,
      itemsProses: [],
      totalRowsProses: 1,
      currentPageProses: 1,
      perPageProses: 5,
      disabledProses: true,
      tableBusy: false,
      jenis_pasien: "",
      optionsJenisPasien: [
        {value: "JKN", text: "JKN"},
        {value: "NON JKN", text: "NON JKN"}
      ],
      nomor_kartu: "",
      pasien_baru: "",
      optionsPasienBaru: [
        {value: "1", text: "Ya"},
        {value: "0", text: "Tidak"}
      ],
      no_rm: "",
      jenis_kunjungan: "",
      nomor_refrensi: "",
      keterangan: "",
      pendaftaran_id: null,
      kode_booking: "",
    };
    
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    disabledPoli(){
      if (this.data.idDokter && this.data.jadwal_dokter_id && this.data.idPoli && this.no_rm) {
          return false;
      } else {
          return true;
      }
    }
  },
  activated() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.idp = localStorage.getItem('idp')
    this.room_id = localStorage.getItem('idp')
    this.getDatas();
    this.$socket.emit("joinRoom", this.room_id);
  },
  created() {
    this.$socket.off("refresh_layar_poli")
    this.$socket.on("refresh_layar_poli", (data) => {
      console.log(data);
      if(this.idPolinya){
        this.getListAntrianLoket(this.idPolinya)
        this.getListAntrianProses()
        this.getListAntrianSelesai()
      }else {
        this.simpanData();
      }
    });
    this.$socket.off("refresh_admin_poli")
    this.$socket.on("refresh_admin_poli", (data) => {
      console.log(data, 'ini dataa');
      if(this.locked == true){
        if(this.idPolinya){
          this.getListAntrianLoket(this.idPolinya)
          this.getListAntrianProses()
          this.getListAntrianSelesai()
        }else {
          this.simpanData();
        }
      }
    });
    this.$socket.on('error', (data) => {
        console.log(data, 'ini error');
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateTanpaPanggil(data) {
      let vm = this;
      vm.antrian_id = data.antrian_id;
      vm.no_proses = data.no_antrian
      vm.no_panggilan = `${data.nomor_antrian}`;
      vm.kode_panggilan = data.initial;
      vm.kode_booking = data.kode_booking
      vm.disabledProses = false
    },
    update(data) {
      let vm = this;
      let tgl = moment(vm.tanggal_sesi).format("YYYY-MM-DD");
      vm.pendaftaran_id = data.pendaftaran_id
      console.log(data, 'ini datanyaaaaaaaaaaaaaaaaaaaa');
      this.$socket.emit("panggil_poli", {
        ms_poli_id: vm.poli_id,
        id: data.antrian_id,
        status_antrian: 1,
        antrian_no: data.nomor_antrian,
        tanggal_antrian: tgl,
        initial: data.initial,
        no_antrian: data.no_antrian,
        room_id: `${vm.idp}${vm.poli_id}`,
        puskesmas_id: vm.idp
      });
      vm.antrian_id = data.antrian_id;
      vm.no_proses = data.no_antrian
      vm.no_panggilan = `${data.nomor_antrian}`;
      vm.kode_panggilan = data.initial;
      vm.kode_booking = data.kode_booking
      vm.disabledProses = false
      vm.panggil()
      // setTimeout(() => {
      //   vm.panggil();
      // }, 2000);
    },
    mulaiProses() {
      let vm = this;
      let tgl = moment(vm.tanggal_sesi).utcOffset('+0700').format("YYYY-MM-DD HH:mm");
      console.log(vm.poli_id, vm.antrian_id);
      vm.$socket.emit("panggil_poli", {
        ms_poli_id: vm.poli_id,
        id: vm.antrian_id,
        tanggal_antrian: tgl,
        waktu_mulai: tgl,
        status_antrian: 2,
        pendaftaran_id: vm.pendaftaran_id,
        room_id: `${vm.room_id}${vm.poli_id}`,
        puskesmas_id: vm.room_id
      });
      vm.sedang_proses = true;
      vm.disabledProses = true
      vm.on_process = true
    },
    batalProses() {
      let vm = this;
      let tgl = moment(vm.tanggal_sesi).format("YYYY-MM-DD");
      vm.$socket.emit("panggil_poli", {
        ms_poli_id: vm.poli_id,
        id: vm.antrian_id,
        status_antrian: 0,
        room_id: `${vm.room_id}${vm.poli_id}`,
        tanggal_antrian: tgl,
        puskesmas_id: vm.room_id
      });
      vm.disabledBatal = true
      vm.disabledSelesai = true
      vm.sedang_proses = false;
      vm.on_process = false
    },
    async getDataPasien(x){
      let vm = this
      let pasien = await axios.get(ipBackend + "member/cekPasien/"+ x);
      // console.log(pasien, 'ini data pasien');
      vm.nama_pasien = pasien.data.data.data[0].namaPasien
      vm.nomor_kartu = pasien.data.data.data[0].noBpjs
      
      console.log(pasien);
    },
    getListAntrianProses(){
      let vm = this
      let tgl = moment(vm.tanggal_sesi).format("YYYY-MM-DD");
      vm.$axios
        .post("/antrian/list", {
          tanggal_antrian: tgl,
          poli_layanan: 2,
          status_antrian: 2,
        })
        .then((res) => {
          vm.itemsProses = res.data.data;
          for (let i = 0; i < vm.itemsProses.length; i++) {
            let x = vm.itemsProses[i];
            x.no = i + 1;
            x.no_antrian = `${x.initial}${x.nomor_antrian}`;
            if(vm.poli_id == x.ms_poli_id){
              if(!vm.no_proses){
                vm.antrian_id = x.antrian_id;
                vm.no_proses = x.no_antrian
                vm.no_panggilan = `${x.nomor_antrian}`;
                vm.kode_panggilan = x.initial;
              }
              
              // vm.sedang_proses = true;
              // vm.on_process = true
              // vm.disabledBatal = false
              // vm.disabledSelesai = false
            }
            for (let j = 0; j < vm.listPoli.length; j++) {
              let y = vm.listPoli[j];
              if(x.poli_id == y.id){
                // console.log('aaaaaaaaaaaa');
                x.nama_poli = y.nama
              }
            }
          }
          vm.totalRowsProses = vm.itemsProses.length;
          console.log(vm.itemsProses, "items Proses");
          //   console.log(vm.kode_panggilan, vm.no_panggilan);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListAntrianSelesai(){
      let vm = this
      let tgl = moment(vm.tanggal_sesi).format("YYYY-MM-DD");
      vm.$axios
        .post("/antrian/list", {
          tanggal_antrian: tgl,
          poli_layanan: 2,
          status_antrian: 9,
        })
        .then((res) => {
          vm.itemsSelesai = res.data.data;
          for (let i = 0; i < vm.itemsSelesai.length; i++) {
            let x = vm.itemsSelesai[i];
            x.no = i + 1;
            x.no_antrian = `${x.kode_jenis_antrian}${x.nomor_antrian}`;
            for (let j = 0; j < vm.listPoli.length; j++) {
              let y = vm.listPoli[j];
              if(x.poli_id == y.id){
                // console.log('aaaaaaaaaaaa');
                x.nama_poli = y.nama
              }
            }
          }
          vm.totalRowsSelesai = vm.itemsSelesai.length;
          console.log(vm.itemsSelesai, "items selesai");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListAntrianLoket(x){
      let vm = this
      let tgl = moment(vm.tanggal_sesi).format("YYYY-MM-DD");
      vm.$axios
        .post("/antrian/list", {
          tanggal_antrian: tgl,
          status_antrian: 1,
          tipe_antrian: "poli",
          ms_poli_id: x,
        })
        .then((res) => {
          vm.items = res.data.data;
          for (let i = 0; i < vm.items.length; i++) {
            let x = vm.items[i];
            x.no = i + 1;
            x.no_antrian = `${x.initial}${x.nomor_antrian}`;
            for (let j = 0; j < vm.listPoli.length; j++) {
              let y = vm.listPoli[j];
              if(x.poli_id == y.id){
                // console.log(y.nama, 'aaaaaaaaaaaa');
                x.nama_poli = y.nama
                // console.log(x.nama_poli, 'aaaaaaaaaaaa');
              }
            }
          }
          vm.totalRows = vm.items.length;
          console.log(vm.items);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    simpanData() {
      let vm = this;
      let tgl = moment(vm.tanggal_sesi).format("YYYY-MM-DD");
      vm.locked = true;
      vm.keDisabled = false
      this.$socket.emit("joinRoom", `${vm.room_id}${vm.poli_id}`);
      for (let i = 0; i < vm.loket.length; i++) {
        let x = vm.loket[i];
        if (x.value == this.poli_id) {
          vm.loketnya = x.text;
        }
      }
      vm.$axios
        .post("/antrian/list", {
          tanggal_antrian: tgl,
          status_antrian: 1,
          tipe_antrian: "poli",
          ms_poli_id: vm.poli_id
        })
        .then((res) => {
          vm.items = res.data.data;
          for (let i = 0; i < vm.items.length; i++) {
            let x = vm.items[i];
            x.no = i + 1;
            x.no_antrian = `${x.initial}${x.nomor_antrian}`;
            for (let j = 0; j < vm.listPoli.length; j++) {
              let y = vm.listPoli[j];
              if(x.poli_id == y.id){
                // console.log(y.nama, 'aaaaaaaaaaaa');
                x.nama_poli = y.nama
                // console.log(x.nama_poli, 'aaaaaaaaaaaa');
              }
            }
          }
          vm.totalRows = vm.items.length;
          console.log(vm.items);
        })
        .catch((err) => {
          console.log(err);
        });
      vm.$axios
        .post("/antrian/list", {
          tanggal_antrian: tgl,
          status_antrian: 9,
          tipe_antrian: "poli",
          ms_poli_id: vm.poli_id
        })
        .then((res) => {
          vm.itemsSelesai = res.data.data;
          for (let i = 0; i < vm.itemsSelesai.length; i++) {
            let x = vm.itemsSelesai[i];
            x.no = i + 1;
            x.no_antrian = `${x.initial}${x.nomor_antrian}`;
            for (let j = 0; j < vm.listPoli.length; j++) {
              let y = vm.listPoli[j];
              if(x.poli_id == y.id){
                // console.log(y.nama, 'aaaaaaaaaaaa');
                x.nama_poli = y.nama
                // console.log(x.nama_poli, 'aaaaaaaaaaaa');
              }
            }
          }
          vm.totalRowsSelesai = vm.itemsSelesai.length;
          console.log(vm.itemsSelesai, "items selesai");
        })
        .catch((err) => {
          console.log(err);
        });
      vm.$axios
        .post("/antrian/list", {
          tanggal_antrian: tgl,
          status_antrian: 2,
          tipe_antrian: "poli",
          ms_poli_id: vm.poli_id
        })
        .then((res) => {
          vm.itemsProses = res.data.data;
          console.log(res, 'ihiy');
          for (let i = 0; i < vm.itemsProses.length; i++) {
            let x = vm.itemsProses[i];
            x.no = i + 1;
            x.no_antrian = `${x.initial}${x.nomor_antrian}`;
            if(vm.poli_id == x.ms_poli_id){
              if(!vm.no_proses){
                vm.antrian_id = x.antrian_id;
                vm.no_proses = x.no_antrian
                vm.no_panggilan = `${x.nomor_antrian}`;
                vm.kode_panggilan = x.initial;
              }
              // vm.antrian_id = x.antrian_id;
              // vm.no_proses = x.no_antrian
              // vm.no_panggilan = `${x.nomor_antrian}`;
              // vm.kode_panggilan = x.initial;
              // vm.sedang_proses = true;
              // vm.on_process = true
              // vm.disabledBatal = false
              // vm.disabledProses = true
              // vm.booking_id = x.booking_id
            }
            for (let j = 0; j < vm.listPoli.length; j++) {
              let y = vm.listPoli[j];
              if(x.poli_id == y.id){
                // console.log('aaaaaaaaaaaa');
                x.nama_poli = y.nama
              }
            }
          }
          vm.totalRowsProses = vm.itemsProses.length;
          console.log(vm.itemsProses, "items Proses");
          //   console.log(vm.kode_panggilan, vm.no_panggilan);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hapusData() {
      let vm = this;
      vm.locked = false;
      vm.keDisabled = true
      vm.items = [];
      vm.itemsSelesai = [];
      vm.jenis_antrian_id = null;
      vm.poli_id = null;
      vm.loketnya = "";
      vm.no_proses = "";
      vm.sedang_proses = false;
    },
    async getDatas() {
      let vm = this;
      let poli = await vm.$axios.post("/ms_poli/list", {
          halaman: 0,
          jumlah: 1000,
      });
      console.log(poli, 'ini poli');
      vm.poli = []
      for (let i = 0; i < poli.data.data.length; i++) {
        let x = poli.data.data[i];
        let y = {};
        if(x.nama_poli != 'Kunjungan Online'){
          y.text = x.nama_poli;
          y.value = x.ms_poli_id;
          vm.poli.push(y);
        }
        
      }
    },
    
    hapusAntrian(){
      let vm = this
      console.log(vm.dataHapus, 'ini data hapus');
      let id = vm.dataHapus.antrian_id
      vm.$axios.post("/antrian/delete", {id:id})
      .then((res) => {
          if(res.data.status == 200 && res.data.message == 'sukses'){
            vm.$bvModal.hide('modal-delete')
            vm.simpanData()
            vm.dataHapus = ""
          }
          console.log(res, 'ini ress');
        })
        .catch((err) => {
          console.log(err);
        })
    },
    kodenya(){
      let vm = this
      let kode = new Audio(
        require(`../../../assets/sound/sound/${vm.kode_panggilan[0]}.wav`)
      );
      
      if(vm.kode_panggilan.length == 1){
        kode.play()
      }else if(vm.kode_panggilan.length == 2){
        let kode2 = new Audio(
          require(`../../../assets/sound/sound/${vm.kode_panggilan[1]}.wav`)
        );
        kode.play()
        setTimeout(() => {
          kode2.play()
        }, 500);
      }
    },
    panggil() {
      this.sedang_manggil = true;
      this.sedang_proses = true
      let nomor_antrian = new Audio(
        require(`../../../assets/sound/sound/nomor_antrian.wav`)
      );
      // let kode = new Audio(
      //   require(`../../../assets/sound/sound/${this.kode_panggilan}.wav`)
      // );
      if (this.no_panggilan.length == 1) {
        let audio = new Audio(
          require(`../../../assets/sound/sound/${this.no_panggilan}.wav`)
        );
        nomor_antrian.play();
        setTimeout(() => {
          this.kodenya()
          setTimeout(() => {
            audio.play();
            this.lokete();
          }, 1000);
        }, 2000);
      } else if (this.no_panggilan.length == 2) {
        if (this.no_panggilan == 10 || this.no_panggilan == 11) {
          let audio = new Audio(
            require(`../../../assets/sound/sound/${this.no_panggilan}.wav`)
          );
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya()
            setTimeout(() => {
              audio.play();
              this.lokete();
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] == 1) {
          let kesatu = new Audio(
            require(`../../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
          );
          let kedua = new Audio(
            require(`../../../assets/sound/sound/belas.wav`)
          );
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya()
            setTimeout(() => {
              kesatu.play();
              setTimeout(() => {
                kedua.play();
                this.lokete();
              }, 1000);
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] != 1) {
          if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/puluh.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/puluh.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        }
      } else if (this.no_panggilan.length == 3) {
        if (this.no_panggilan == 100) {
          let audio = new Audio(
            require(`../../../assets/sound/sound/seratus.wav`)
          );
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya()
            setTimeout(() => {
              audio.play();
              this.lokete();
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] == 1) {
          if (
            (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 0) ||
            (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 1)
          ) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[1]}${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[2] == 0) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/puluh.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 1) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/belas.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/seratus.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/puluh.wav`)
            );
            let keempat = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        } else if (this.no_panggilan[0] != 1) {
          if (
            (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 0) ||
            (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 1)
          ) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[1]}${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0 && this.no_panggilan[2] == 0) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/ratus.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[2] == 0) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            let keempat = new Audio(
              require(`../../../assets/sound/sound/puluh.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 1) {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            let keempat = new Audio(
              require(`../../../assets/sound/sound/belas.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[0]}.wav`)
            );
            let kedua = new Audio(
              require(`../../../assets/sound/sound/ratus.wav`)
            );
            let ketiga = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
            );
            let keempat = new Audio(
              require(`../../../assets/sound/sound/puluh.wav`)
            );
            let kelima = new Audio(
              require(`../../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
            );
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya()
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      setTimeout(() => {
                        kelima.play();
                        this.lokete();
                      }, 1000);
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        }
      }
      //    else if (this.no_panggilan.length == 4) {
      //     if (this.no_panggilan == 1000) {
      //       let audio = new Audio(
      //         require(`../../../assets/sound/sound/seribu.wav`)
      //       );
      //       nomor_antrian.play();
      //       setTimeout(() => {
      //         audio.play();
      //         this.loket();
      //       }, 2000);
      //     } else if (this.no_panggilan[0] == 1) {
      //       if (this.no_panggilan[1] == 0 && this.no_panggilan[2] == 0) {
      //         let kesatu = new Audio(
      //           require(`../../../assets/sound/sound/seribu.wav`)
      //         );
      //         let kedua = new Audio(
      //           require(`../../../assets/sound/sound/${this.no_panggilan[3]}.wav`)
      //         );
      //         nomor_antrian.play();
      //         setTimeout(() => {
      //           kesatu.play();
      //           setTimeout(() => {
      //             kedua.play();
      //             this.loket();
      //           }, 1000);
      //         }, 2000);
      //       } else if (
      //         (this.no_panggilan[1] == 0 &&
      //           this.no_panggilan[2] == 1 &&
      //           this.no_panggilan[3] == 0) ||
      //         (this.no_panggilan[1] == 0 &&
      //           this.no_panggilan[2] == 1 &&
      //           this.no_panggilan[3] == 1)
      //       ) {
      //         let kesatu = new Audio(
      //           require(`../../../assets/sound/sound/seribu.wav`)
      //         );
      //         let kedua = new Audio(
      //           require(`../../../assets/sound/sound/${this.no_panggilan[2]}${this.no_panggilan[3]}.wav`)
      //         );
      //         nomor_antrian.play();
      //         setTimeout(() => {
      //           kesatu.play();
      //           setTimeout(() => {
      //             kedua.play();
      //             this.loket();
      //           }, 1000);
      //         }, 2000);
      //       }
      //       let kesatu = new Audio(
      //         require(`../../../assets/sound/sound/seribu.wav`)
      //       );
      //       let kedua = new Audio(
      //         require(`../../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
      //       );
      //       let ketiga = new Audio(
      //         require(`../../../assets/sound/sound/ratus.wav`)
      //       );
      //       let keempat = new Audio(
      //         require(`../../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
      //       );
      //       let kelima = new Audio(
      //         require(`../../../assets/sound/sound/puluh.wav`)
      //       );
      //       let keenam = new Audio(
      //         require(`../../../assets/sound/sound/${this.no_panggilan[3]}.wav`)
      //       );
      //       nomor_antrian.play();
      //       setTimeout(() => {
      //         kesatu.play();
      //         setTimeout(() => {
      //           kedua.play();
      //           setTimeout(() => {
      //             ketiga.play();
      //             setTimeout(() => {
      //               keempat.play();
      //               setTimeout(() => {
      //                 kelima.play();
      //                 setTimeout(() => {
      //                   keenam.play();
      //                   this.loket();
      //                 }, 1000);
      //               }, 1000);
      //             }, 1000);
      //           }, 1000);
      //         }, 1000);
      //       }, 2000);
      //     }
      //   }
    },
    lokete() {
      let loket = new Audio(require(`../../../assets/sound/sound/di_poli.wav`));
      setTimeout(() => {
        loket.play();
        this.nama_loket();
      }, 1000);
    },
    nama_loket() {
      // let i = this.kode_poli;
      
      // let nama_loket = new Audio(
      //   require(`../../../assets/sound/sound/${i}.wav`)
      // );
      
      setTimeout(() => {
        // nama_loket.play()
        this.sedang_manggil = false;
        if(this.on_process == false){
          this.sedang_proses = false
        }
      }, 1500);
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
};
</script>
<style scoped>
.box-panggilan {
  width: 275px;
  height: 440px;
  left: 905px;
  top: 37px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
</style>
